<template>
    <div>
        <styled-tooltip
            v-model="showFilteredDropdown"
            position="left"
            :max-width="185"
            :high-index="true"
            :no-padding="true"
            :rounded-border="true">
            <div
                v-if="filteredChannels.length"
                class="filtered-link"
                @click="showFilteredDropdown = true">
                {{ filteredChannels.length }} Filtered Channels
            </div>
            <template #content>
                <div class="filtered-content">
                    <div class="filtered-title">
                        Filtered Channels
                    </div>
                    <div class="filtered-channels">
                        <div
                            v-for="(channel, index) in filteredChannels"
                            :key="index"
                            class="filtered-channel">
                            <img
                                v-if="channel === PLATFORM_FACEBOOK"
                                class="filtered-logo"
                                :src="`/img/platforms/meta-blue.svg`">
                            <img
                                v-else
                                class="filtered-logo"
                                :src="`/img/platforms/${channel}.svg`">
                            <span>{{ capitalizeAndSwapFB(channel) }}</span>
                        </div>
                    </div>
                    <div class="filtered-details">
                        See filtered reasons for details
                    </div>
                </div>
            </template>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import { capitalizeAndSwapFB } from '@/helpers/capitalizeAndSwapFB';
import { mapState } from 'vuex';

export default {
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            activatedChannels: state => state.settings.activatedChannels,
        })
    },
    watch: {
        activatedChannels() {
            this.setChannels();
        }
    },
    data() {
        return {
            showFilteredDropdown: false,
            filteredChannels: [],
            PLATFORM_FACEBOOK,
            capitalizeAndSwapFB
        };
    },
    mounted() {
        this.setChannels();
    },
    methods: {
        setChannels() {
            const allChannels = [];
            const filteredChannels = new Set();
            // eslint-disable-next-line
            const channelsForChecking = structuredClone(this.activatedChannels);
            channelsForChecking.push('amazon_auto'); // check amazon as well
            channelsForChecking.forEach(channel => {
                const reasons = this.item.exports[channel].filtered_vehicle_reasons;
                if (reasons.length) {
                    // eslint-disable-next-line
                    reasons.forEach(r => {
                        allChannels.push(channel);
                    });
                }
            });
            if (allChannels.length) {
                allChannels.forEach(channel => {
                    const channelName = channel.substring(0, channel.indexOf('_'));
                    filteredChannels.add(channelName);
                });
                this.filteredChannels = Array.from(filteredChannels);
            }

        },
    }
};
</script>

<style lang="scss" scoped>
.filtered-content {
    width: 185px;
    font-size: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
}
.filtered-link {
    color: $blue-bg;
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}
.filtered-channels {
    padding: 0 35px;
    margin-bottom: 15px;
}
.filtered-details {
    background-color: #F8F8F8;
    font-style: italic;
    padding: 11px 25px;
}
.filtered-title {
    color: $blue-bg;
    padding: 15px;
}
.filtered-channel {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.filtered-logo {
    max-width: 20px;
    height: auto;
    margin-right: 10px;
}
</style>